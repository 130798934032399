/**
 *  Same as houston/.../IntegrationIdentifier enum.
 *
 *  Important!
 *  Keep these enums consistent.
 *  If you change one of them - you should change another.
 *
 * */
export enum IntegrationIdentifier {
    NEEDLES = 'Needles',
    NEEDLES_NEOS = 'Needles Neos',
    SMART_ADVOCATE = 'Smart Advocate',
    LITIFY = 'Litify',
    LUI = 'LUI',
    CLIENT_CONNECT = 'Client Connect',
    LAW_BASE = 'LawBase',
    FILEVINE = 'Filevine'
}

export namespace IntegrationIdentifier {
    export function getValues(): string[] {
        return Object.keys(IntegrationIdentifier)
            .filter(key => typeof IntegrationIdentifier[key] === 'string')
            .map(key => IntegrationIdentifier[key]);
    }

    export function getKeys(): string[] {
        return Object.keys(IntegrationIdentifier)
            .filter(key => typeof IntegrationIdentifier[key] === 'string');
    }
}

